@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,700;1,500&display=swap');
body{
    font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
}

h2 {
    font-size: clamp(26px, 3vw, 40px);
    font-weight: 600;
    margin-bottom: 25px;
    @media (max-width:767px) {
        margin-bottom: 15px;
    }
}

a{
    text-decoration: none;
    font-weight: 500;
}

p {
    line-height: 28px;
}


