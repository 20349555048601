@font-face {
    font-family: "Nioicon";
    src: url("../../fonts/Nioicon.eot");
    src: url("../../fonts/Nioicon.eot#iefix") format("embedded-opentype"), url("../../fonts/Nioicon.ttf") format("truetype"), url("../../fonts/Nioicon.woff") format("woff"), url("../../fonts/Nioicon.svg#Nioicon") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    border-radius: 50px;
    transition: 0.3s;
    padding: 5px;
    cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
    background-color: #98CED0;
}


.ni {
    font-family: "Nioicon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ni-eye:before {
    content: "\ea3c";
}

.ni-eye-off:before {
    content: "\ea3b";
}

#close--modal,
.close--login,
.close--forgot {
    background: white;
    width: 35px;
    aspect-ratio: 1;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    position: absolute;
    top: 2%;
    right: 5%;
    cursor: pointer;
}

.custom--popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(146, 146, 146, .95);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 60px 15px 60px;
    z-index: 999;
    display: none;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: lighten($black, 90%);
    }

    &::-webkit-scrollbar-thumb {
        background: $blue;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $blue;
    }

    &.active--popup {
        display: flex;
    }

    .custom--popup--wrapper {
        max-width: 500px;
        margin: 0 auto;
        background-color: $white;

        .switcher--tabs {
            ul {
                display: flex;

                @media (max-width:767px) {
                    flex-direction: column;
                }

                li {
                    width: 50%;

                    @media (max-width:767px) {
                        width: 100%;
                    }

                    a {
                        color: $black;
                        padding: 20px 20px;
                        display: block;
                        text-align: center;
                        border-radius: 0;

                        &:hover {
                            color: $white;
                        }

                        &.active {
                            background-color: $black;
                            color: $white;
                        }
                    }
                }
            }
        }

        .login--form {
            padding: 50px;
            display: none;

            @media (max-width:767px) {
                padding: 35px 15px;
            }

            &.active--from {
                display: block;
            }

            .login--form--wrapper {
                form {
                    display: flex;
                    flex-direction: column;
                    gap: 30px;

                    .form--group {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        justify-content: space-between;

                        input,
                        select,
                        textarea {
                            padding: 15px 0;
                            border-bottom: 1px solid black;
                        }

                    }

                    .password--container {
                        position: relative;

                        .password-hide--show {
                            position: absolute;
                            bottom: 15px;
                            right: 20px;
                        }
                    }

                    .form--checkbox {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        gap: 15px;

                        @media (max-width:767px) {
                            flex-wrap: wrap;
                        }

                        input[type=checkbox] {
                            accent-color: $blue;
                            width: 15px;
                            height: 15px;
                            position: relative;
                            top: 1px;
                        }

                        .lost--password {
                            a {
                                &:hover {
                                    color: $blue;
                                }
                            }
                        }
                    }

                    .form--action {
                        .submit--from {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

// deafult

.default--popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(146, 146, 146, .95);
    display: none;
    align-items: flex-start;
    justify-content: center;
    padding: 60px 15px 60px;
    z-index: 999;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: lighten($black, 90%);
    }

    &::-webkit-scrollbar-thumb {
        background: $blue;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $blue;
    }

    &.active--popup {
        display: flex;
    }

    .default--popup--wrapper {
        max-width: 960px;
        margin: 0 auto;
        background-color: $white;

        .default--form {
            padding: 50px;
            display: none;

            @media (max-width:767px) {
                padding: 35px 15px;
            }

            &.active--from {
                display: block;
            }

            .default--form--wrapper {
                .form--title {
                    text-align: center;
                    padding-bottom: 20px;
                }

                form {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px;

                    @media (max-width:767px) {
                        display: flex;
                        flex-direction: column;
                        gap: 15px;
                    }

                    .form--group {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        justify-content: space-between;

                        label {
                            width: 100%;
                            display: block;
                        }

                        input,
                        select {
                            padding: 15px 0;
                            border-bottom: 1px solid $black;
                            width: 100%;
                        }

                        textarea {
                            padding: 8px 0;
                            border-bottom: 1px solid $black;
                            resize: none;
                        }
                        .total_div {
                            height: 100%;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            justify-content: space-between;
                            @media(max-width: 767px){
                                width: 100%;
                            }
                        }
                    }

                    .right--active--total {
                        grid-column: span 2;
                        align-items: flex-end;
                        .total_div {
                            width: calc(50% - 15px);
                            @media(max-width: 767px){
                                width: 100%;
                            }
                        }
                    }

                    .defualt--phone{
                        position: relative;
                        .phone--set {
                            position: absolute;
                            bottom: 15px;
                        }
                        input{
                            padding-left: 22px;
                        }
                    }

                    .form--checkbox {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: 15px;

                        @media (max-width:767px) {
                            flex-wrap: wrap;
                        }

                        input[type=checkbox] {
                            accent-color: $blue;
                            width: 15px;
                            height: 15px;
                            position: relative;
                            top: 1px;
                        }

                        .lost--password {
                            a {
                                &:hover {
                                    color: $blue;
                                }
                            }
                        }
                    }

                    .form--checkbox--option {
                        display: flex;
                        align-items: flex-start;
                        gap: 15px;
                        border-bottom: 1px solid $black;
                        min-height: 80px;

                        .checkbox--option {
                            width: fit-content;

                            input[type=checkbox] {
                                accent-color: $blue;
                                width: 15px;
                                height: 15px;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }

                    .form--radio--option {
                        display: flex;
                        align-items: flex-start;
                        gap: 15px;
                        border-bottom: 1px solid $black;
                        min-height: 80px;

                        .radio--option {
                            width: fit-content;

                            input[type=radio] {
                                accent-color: $blue;
                                width: 15px;
                                height: 15px;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }

                    .form--action {
                        grid-column: span 2;
                        @include flex();
                        gap: 15px;

                        @media (max-width:767px) {
                            grid-column: span 1;
                        }

                        .submit--from {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.static--popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(146, 146, 146, .95);
    display: none;
    align-items: flex-start;
    justify-content: center;
    padding: 60px 15px 60px;
    z-index: 999;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: lighten($black, 90%);
    }

    &::-webkit-scrollbar-thumb {
        background: $blue;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: $blue;
    }

    &.active--popup {
        display: flex;
    }

    .static--popup--wrapper {
        max-width: 660px;
        margin: 0 auto;
        background-color: $white;

        .static--form {
            padding: 50px;

            @media (max-width:767px) {
                padding: 35px 15px;
            }

            .static--form--wrapper {
                .form--title {
                    text-align: center;
                    padding-bottom: 20px;
                }

                form {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 30px;

                    @media (max-width:767px) {
                        grid-template-columns: repeat(1, 1fr);
                    }

                    .form--group {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        justify-content: space-between;

                        input,
                        select {
                            padding: 15px 0;
                            border-bottom: 1px solid $black;
                        }

                        textarea {
                            padding: 8px 0;
                            border-bottom: 1px solid $black;
                            resize: none;
                        }

                        &.span--2 {
                            grid-column: span 2;
                        }
                    }

                    .password--container {
                        position: relative;

                        .password-hide--show {
                            position: absolute;
                            bottom: 15px;
                            right: 20px;
                        }
                    }


                    .form--checkbox {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        gap: 15px;

                        @media (max-width:767px) {
                            flex-wrap: wrap;
                        }

                        input[type=checkbox] {
                            accent-color: $blue;
                            width: 15px;
                            height: 15px;
                            position: relative;
                            top: 1px;
                        }

                        .lost--password {
                            a {
                                &:hover {
                                    color: $blue;
                                }
                            }
                        }
                    }

                    .form--checkbox--option {
                        display: flex;
                        align-items: flex-start;
                        gap: 15px;
                        border-bottom: 1px solid $black;
                        min-height: 80px;

                        .checkbox--option {
                            width: fit-content;

                            input[type=checkbox] {
                                accent-color: $blue;
                                width: 15px;
                                height: 15px;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }

                    .form--radio--option {
                        display: flex;
                        align-items: flex-start;
                        gap: 15px;
                        border-bottom: 1px solid $black;
                        min-height: 80px;

                        .radio--option {
                            width: fit-content;

                            input[type=radio] {
                                accent-color: $blue;
                                width: 15px;
                                height: 15px;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }

                    .form--action {
                        grid-column: span 2;
                        @include flex();
                        gap: 15px;

                        @media (max-width:767px) {
                            grid-column: span 1;
                        }

                        .submit--from {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.session--out--popup--main {
    .default--popup--wrapper {
        max-width: 500px;

        .session--out--popup {
            padding: clamp(20px, 3vw, 40px);
            text-align: center;

            h3 {
                font-size: clamp(16px, 1.1vw, 18px);
                margin-bottom: 20px;
            }

            .btn {
                padding: clamp(10px, 1.1vw, 10px) clamp(30px, 2vw, 30px);
                min-height: 40px;
            }
        }
    }

}

.back--form--btn {
    span {
        color: $dark-blue;
        cursor: pointer;
    }
}

.default--popup {
    .form--services {
        .from--wrapper {
            top: 16%;
        }
    }

}

.user--action--popup {
    align-items: center;

    .default--popup--wrapper {
        max-width: 500px;

        .user--action--datta {
            padding: 30px 30px 60px;

            @media (max-width:767px) {
                padding: 30px 15px;
            }

            h2 {
                text-align: center;
                padding-bottom: 10px;
                font-size: clamp(20px, 2vw, 30px);
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 5px;
                text-align: center;

                li {
                    padding-block: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .request--service--btn {
                        display: flex;
                        gap: 15px;
                        margin-top: 30px;
                        justify-content: center;

                        @media (max-width:500px) {
                            margin-top: 20px;
                            flex-direction: column;
                            align-items: center;
                        }
                    }

                    .btn {
                        width: max-content;
                        padding: clamp(10px, 1.1vw, 10px) clamp(30px, 2vw, 30px);
                        min-height: 40px;
                    }

                    h3 {
                        font-size: 18px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

}

.react-datepicker__input-container {
    &:before {
        content: '';
        width: 20px;
        aspect-ratio: 1;
        display: block;
        background-image: url(../../image/calender--icon.svg);
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        background-size: 13px;
        background-repeat: no-repeat;
        background-position: 9px center;
        z-index: 1;
        transition: 0.3s;
        border-radius: 50px;
        padding: 6px;
    }

    &:hover::before {
        background-color: #98CED0;
    }

    input {
        z-index: 2;
        position: relative;
        background: transparent;
    }
}

.react-datepicker-popper[data-placement^=bottom],
.react-datepicker-popper[data-placement^=top] {
    padding-top: 10px;
    width: fit-content;
}