%card_shadow {
    background-color: $white;
    box-shadow: $box-shadow;
    border: 1px solid rgba(0,0,0,.20);
}

%before_content {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

%heading--24 {
    font-size: clamp(18px, 1.5vw, 24px);
    font-weight: 500;
}