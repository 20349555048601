body {
    color: $black;
}
p {
    color: $text-color;
}

a {
    color: $black;
    &:hover {
        text-decoration: none;
        color: $black;
    }
}

