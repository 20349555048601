$white:#ffffff;
$black:#000000;
$blue:#98CED0;
$dark-blue:#1A8D92;
$dark-gray:#979494;
$yellow: #E7DF59;
$black-2:#010101;
$text-color:#7C7C7C;
$green:#76b53b;
$red:red;
$sky:#69adf3;
$box-shadow:0px 3px 9px rgba(0,0,0,0.25);
$transition: 0.4s ease-in-out;
$montserrat: 'Montserrat', sans-serif;