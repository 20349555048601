.quotation--details {
    padding: clamp(40px, 6vw, 100px) 0;
    .grid--container {
        
        .quotation--details--body{
            padding: 30px;
            border: 1px solid $blue;
            @media (max-width:767px) {
                padding: 20px 15px;
            }
            h3 {
                font-size: clamp(20px, 2vw, 30px);
                font-weight: 500;
                margin-bottom: 20px;
            }
            table {
                padding-top: 30px;
                width: 100%;
                @media (max-width:600px) {
                    padding-top: 0px;
                }
                tr {
                    @include flex(flex-start);
                    gap: 30px;
                    &:not(:last-child) {
                        border-bottom: 1px solid $blue;
                    }

                    &:nth-child(odd) {
                        background-color: lighten($black, 95%);
                    }
                    @media (max-width:600px) {
                        flex-direction: column;
                        gap:0px;
                        align-items: flex-start;
                       
                    }
                    th {
                        text-align: left;
                        width: 260px;
                        padding: 15px 10px;
                        font-weight: 600;
                        @media (max-width:600px) { 
                            padding: 7px 10px 0;
                        }
                    }
                    td {
                        padding: 15px;
                        @media (max-width:600px) { 
                            padding: 7px 10px 7px;
                        }
                    }
                }
            }
        }
    }
}