.btn {
    background-color: $blue;
    padding: clamp(15px, 1.1vw, 17px) clamp(30px, 2vw, 30px);
    font-size: 14px;
    border-radius: 8px;
    text-transform: uppercase;
    min-height: 50px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    transition: $transition;
    z-index: 1;
    &.white--btn {
        background-color: $white;
    }
    &.black--btn {
        background-color: $black;
        color: $white;
    }
    &::before{
        content: "";
        width: 100%;
        height: 100%;
        background-color: $black;
        position: absolute;
        left: 0;
        top: 0;
        transform: scaleX(0);
        transition: $transition;
        z-index: -1;
    }
    &:hover:before {
        transform: scaleX(1);
    }
    &:hover {
        color: $white;
    }
}
.title--heading {
    position: relative;
    text-align: center;
    h3 {
        position: relative;
        font-size: 18px;
        font-weight: 400;
        text-transform: uppercase;
        color: $blue;
        display: inline-flex;
        align-items: flex-end;
        min-height: 190px;
        @media (max-width:1024px) {
            min-height: 130px;
        }
        &:before {
            content: "";
            width: 4px;
            height: 150px;
            background-color: $blue;
            position: absolute;
            bottom: 35px;
            left: 50%;
            transform: translateX(-50%);
            @media (max-width:1024px) {
                height: 80px;
            }
        }
    }
    h2 {
        font-size: clamp(24px, 3vw, 40px);
        font-weight: 600;
        color: $black;
        margin: 35px 0;
        @media (max-width:767px) {
            margin: 25px 0;
        }
        @media (max-width:767px) {
            margin: 15px 0;
        }
    }
}

.line--1 {
    width: 4px;
    height: 150px;
    background-color: $blue;
    margin: 25px auto 0;
    @media (max-width:1024px) {
        height: 90px;
    }
}

.heading--big {
    h2 {
        font-size: clamp(36px, 5.7vw, 90px);
        font-weight: 700;
        font-family: $montserrat;
        color: $black;
        line-height: 1;
    }
}

.my--auto {
    margin-block: auto;
}
.mx--auto {
    margin-inline: auto;
}

.y--center {
    display: flex;
    align-items: center;
}
.x--center {
    display: flex;
    justify-content: center;
}

.p--0 {
    padding: 0;
}

.order--tab--1 {
    @media (max-width:1024px) {
        order: 1;
    }
}
.align--center {
    text-align: center;
}

.pt--15 {
    padding-top: 15px;
}