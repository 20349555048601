
.servies--portable {
    padding: clamp(40px, 6vw, 100px) 0;
    .about--portable--wrapper {
        display: flex;
        gap: clamp(25px, 4vw,75px);
        align-items: center;
        .about--portable--data {
            width: 100%;
            @media (max-width:767px) {
                width: 100%;
            }
            h2 {    
                margin-top: 40px;
                @media (max-width:767px) {
                    margin-top: 0px;
                }
            }
            .highlight--text {
                font-size: clamp(18px, 1.3vw, 24px);
                color: $black;
                line-height: 1.6;
                margin-bottom: 30px;
                text-align: justify;
            }
            p {
                text-align: justify;
            }
        }
        
    }
}

.services--tabs {
    padding-bottom: clamp(40px, 6vw, 100px);
    .servies--list--tab {
        ul {
            display: flex;
            flex-flow: column;
            gap: 15px;
            li {
                a {
                    border: 1px solid lighten($black, 0%);
                    border-radius: 5px;
                   @include flex();
                    min-height: 60px;
                    font-size: clamp(16px, 1.1vw, 18px);
                    transition: $transition;
                    &:hover, &.active {
                        background-color: $blue;
                        border: 1px solid lighten($blue, 0%);
                    }
                }
            }
        }
    }
    .servies--list--content {
        background-color: $blue;
        padding: clamp(20px, 2.5vw, 45px);
        border-radius: 5px;
        height: 100%;
        max-width: 500px;
        margin: 0 auto;
        .services--list--content--item {
            p {
                color: $black;
            }
            .servies--inner--form--wrapper {
                display: flex;
                gap: 20px;
                @media (max-width:767px) {
                    flex-direction: column;
                    gap: 0px;
                }
                .servies--inner--form {
                    margin-top: 20px;
                    max-width: 100%;
                    @media (max-width:767px) {
                        max-width: 100%;
                    }
                    .form--group {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        margin-bottom: 15px;
                        input {
                            padding: 15px;
                            border-radius: 5px;
                            width: 100%;

                            &[type='file']{
                                padding: 15px;
                                border-radius: 5px;
                                width: 100%;
                                background-color: #ffffff;
                            }

                        }
                        
                    }
                }
                .service--map {
                    flex-grow: 1;
                    padding-block: 25px;
                    @media (max-width:767px) {
                        padding-block-start: 0px
                    }
                    iframe {
                        height: 100%;
                    }
                }
            }
            
            ul {
                display: flex;
                gap: 15px;
                flex-wrap: wrap;
                margin: 20px 0px;
                @media (max-width:767px) {
                    max-width: 100%;
                    flex-direction: column;
                 }
                li {
                    label {
                        border: 1px solid lighten($white, 0%);
                        border-radius: 5px;
                        padding: 10px 15px;
                       @include flex(flex-start);
                        min-height: 50px;
                        font-size: clamp(16px, 1.1vw, 18px);
                        transition: $transition;
                        cursor: pointer;
                        &:hover {
                            background-color: $white;   
                            border: 1px solid lighten($white, 0%);
                        }
                        input {
                            width: 18px;
                            height: 18px;
                            margin-right: 10px;
                            caret-color: $blue;
                            
                        }
                        span {
                            white-space: nowrap;
                        }
                    }
                }
            }
            .service--action {
                .service--action--wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    input {
                        padding: 15px;
                        border-radius: 5px;
                        min-width: 400px;
                        @media (max-width:767px) {
                            min-width: auto;
                            width: 100%;
                            border-radius: 5px;
                        }
                    }
                    button {
                        border-radius: 5px 5px 5px 5px;
                        &.btn--radius {
                            @media (max-width:767px) { 
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}