.cart--table {
    .cart--table--wrapper {
        padding: clamp(40px, 6vw, 100px) 0;
        table.cart--table--items {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;
            thead {
                tr {
                    border-bottom: 1px solid lighten($black, 90%);
                    th {
                        padding: 15px 0px;
                        text-align: left;
                        font-weight: 500;
                        @media (max-width:767px) {
                            &:not(:first-child) {
                                display: none;
                            }
                        }
                    }
                  
                }
            }
            tbody {
                tr {
                    border-bottom: 1px solid lighten($black, 90%);
                    
                    @media (max-width:767px) {
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 35px;
                        border: none;
                        &:nth-child(even) {
                            background-color: lighten($black, 95%);
                        }
                       
                    }
                    td {
                        padding: 30px 10px 30px 0px;
                        @media (max-width:767px) {
                            padding: 15px 15px 15px 15px;
                            border-bottom: 1px solid lighten($black, 90%);
                        }
                        &.product--info {
                            .product-wrap {
                                display: flex;
                                gap: 15px;
                                .product--thumbnuil {
                                    width: 100px;
                                    
                                    img {
                                        width: 100%;
                                        aspect-ratio: 1/ 1;
                                        object-fit: cover;
                                        padding: 10px;
                                        border: 1px solid lighten($black, 90%);
                                    }
                                }
                                .product--info--data {
                                    .pd--title {
                                        font-weight: 500;
                                        font-size: 16px;
                                        margin-bottom: 5px;
                                    }
                                    dl {
                                        display: flex;
                                        gap: 8px;
                                        font-size: 14px;
                                    }
                                }
                            }
                        }
                        &.product-price {
                            span {
                                font-weight: 500;
                            }
                        }

                        &.product-subtotal {
                            span {
                                font-weight: 500;
                            }
                        }
                        &.product-quantity {
                            .quantity {
                                position: relative;
                                width: 120px;
                                input {
                                    min-height: 40px;
                                    text-align: center;
                                    font-size: 16px;
                                    font-weight: 500;
                                    width: 100%;
                                    border: 1px solid #f5f5f5;
                                }
                                button.decrease {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    min-width: 40px;
                                    height: 40px;
                                    font-size: 24px;
                                }
                                button.increase {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    min-width: 40px;
                                    height: 40px;
                                    font-size: 24px;
                                }
                            }
                        }
                        &.remove--product {
                            .delete--product {
                                cursor: pointer;
                                i {
                                    font-size: 22px;
                                    color: lighten($black, 70%);
                                }
                            }
                        }
                        &:last-child {
                            text-align: right;
                            @media(max-width:767px) {
                                text-align: left;
                            }
                        }
                        .hidden--desk {
                            @media(min-width:768px) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .cart--totals {
            width: max-content;
            min-width: 400px;
            margin-left: auto;
            padding: 30px;
            border: 1px solid lighten($black, 90%);
            margin-top: 50px;
            @media(max-width:767px) {
                margin-top: 20px;
                width: 100%;
                min-width: auto;
                padding: 20px 15px;
            }
            .cart--total--table {
                h3.order--title {
                    font-size: clamp(18px, 1.8vw, 24px);
                    font-weight: 500;
                }
                
                ul {
                    margin-top: 20px;
                    li {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 1px solid lighten($black, 90%);
                        padding: 15px 0;
                        span:last-child {
                            font-weight: 500;
                        }
                        &:last-child {
                            border: none;
                        }
                    }
                }
                .checkout--btn {
                    margin-top: 20px;
                    .btn {
                        width: 100%;
                    }
                }
            }
        }
    }
}