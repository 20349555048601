.grid{
    display: grid;
    grid-template-columns: repeat(4, minmax(min-content,1fr));
    gap: 15px;
    @media (max-width:1024px){
        grid-template-columns: repeat(1, minmax(min-content,1fr));
        gap: 0;
        row-gap: 1rem;
    }
    &----{
        grid-column: span 4;
        padding: 15px; 
    }
    &---{
        grid-column: span 3; 
        padding: 15px;  
    }
    &--{
        grid-column: span 2;  
        padding: 15px; 
    }
    &-{
        grid-column: span 1;
        padding: 15px; 
        @media (max-width:992px){
            grid-column: span 2;
        } 
    }
    &--header{
        @include flex($j:space-between)
    }
    &--wrapper{
        width: 100%;
    }
}

.grid--container {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
}