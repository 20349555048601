.user--dashboard {
    .dashboard--wrapper {
        display: flex;
        @media (max-width:1024px) {
            flex-direction: column;
        }
        .user--sidebar {    
            width: 20%;
            padding: 35px 30px 35px 15px;
            border-right: 1px solid lighten($black, 90%);
            @media (max-width:1280px) {
                width: 25%;
            }
            @media (max-width:1024px) {
                width: 100%;
                padding: 35px 15px 35px 15px;
            }
            .menu--icon {
                width: max-content;
                font-size: 28px;
                line-height: 1;
                cursor: pointer;
                padding: 5px 10px;
                width: 45px;
                border: 1px solid lighten($black, 90%);
                @include flex();
                @media(min-width:1025px) {
                    display: none;
                }
                @media (max-width:767px) {
                    font-size: 24px;
                }
            }
            .user--sidebar--container {
                @media (max-width:1024px) {
                    padding-top: 25px;
                 }
                .sidebar--title {
                    h2 {
                        font-size: 14px;
                        text-transform: uppercase;
                        font-weight: 400;
                        color: $text-color;
                        letter-spacing: 0.5px;
                    }
                    &.sidebar--title--2 {
                        margin-top: clamp(40px, 6vw, 90px);
                    }
                    @media (max-width:1024px) {
                        display: none;
                    }
                }
                .sidebar--wrapper {
                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        li {
                            a {
                                border: 1px solid lighten($black, 90%);
                                padding: 16px 35px;
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                gap: 12px;
                                color: $text-color;
                                font-weight: 400;
                                transition: $transition;
                                @media (max-width:767px) {
                                    padding: 12px 15px;
                                 }
                                img {
                                    opacity: .6;
                                }
                                &.active, &:hover {
                                    background-color: $blue;
                                    color: $black;
                                    font-weight: 500;
                                    img {
                                        opacity: 1;
                                    }
                                }
                            }
                        }
                    }
                    &:last-of-type {
                        @media (max-width:1024px) {
                           margin-top: 12px;
                        }
                    }
                }
            }
            
            .put--quoats {
                position: relative;
                margin-top: 40px;
                @media (max-width:1024px) {
                    display: none;
                }
                .head--image {
                    position: absolute;
                    max-width: 100px;
                    width: 100%;
                    color: white;
                    left: 21px;
                    top: 35px;
                    line-height: inherit;
                    font-weight: 600;
                }
                img {
                    width: 100%;
                }
                a {
                    position: absolute;
                    bottom: 15px;
                    left: 50%;
                    background-color: #fff;
                    padding: 10px 20px;
                    border-radius: 10px;
                    transform: translateX(-50%);
                    white-space: nowrap;
                    transition: $transition;
                    &:hover {
                        background-color: $blue;
                    }
                }
            }
        }
        .user--dashboard--content {
            width: 80%;
            padding: 35px 15px 35px 30px;
            @media (max-width:1280px) {
                width: 75%;
            }
            @media (max-width:1024px) {
                width: 100%;
                padding: 0px 15px 35px 15px;
            }
            .user--dashboard--content--wrapper {
                .dashboard--content  {
                    .dashboard--content--title {
                        h2 {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            font-size: 20px;
                            font-weight: 500;
                            @media(max-width:767px) {
                                font-size: 18px;
                            }
                        }
                    }
                    .table--wrapper {
                        border: 1px solid lighten($black, 90%);
                        border-radius: 10px;
                        padding: 5px;
                        overflow-x: auto;
                        .table--title {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            padding: 15px 30px;
                            span {
                                color: $dark-blue;
                                font-weight: 500;
                                a {
                                    color: $dark-blue;
                                }
                            }
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            thead {
                                tr {
                                    background-color: lighten($black, 95%);
                                    th {
                                        text-align: left;
                                        font-size: 14px;
                                        font-weight: 500;
                                        padding: 25px;
                                        @media(max-width:767px) {
                                            padding: 15px;
                                        }
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    &:nth-child(even) {
                                        background-color: lighten($black, 95%);
                                    }
                                    td {
                                        text-align: left;
                                        font-size: 14px;
                                        font-weight: 400;
                                        padding: 25px;
                                        @media(max-width:767px) {
                                            padding: 15px;
                                        }
                                        &.active {
                                            color: $green;
                                        }
                                        &.cancel {
                                            color: $red;
                                        }
                                        &.processing {
                                            color: $sky;
                                        }
                                        
                                        .action--table {
                                            border: 1px solid lighten($black, 90%);
                                            padding: 6px;
                                            min-width: 70px;
                                            border-radius: 30px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;    
                                            background-color: transparent;
                                            transition: background-color 0.4s;
                                            min-height: 30px;
                                            background-image: url("../../image/eye.png");
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            
                                            &:hover {
                                                background-color: $blue;
                                                background-image: url("../../image/eye2.png");
                                            }
                                        } 
                                    }
                                }
                            }
                        }
                    }
                    .dashboard--notification {
                        display: flex;
                        gap: 30px;
                        margin-top: 30px;
                        @media(max-width:767px) {
                            flex-direction: column;
                        }
                        .notification--wrapper   {
                            flex-basis: 50%;
                            border: 1px solid lighten($black, 90%);
                            border-radius: 10px;
                            padding: 30px;
                            @media(max-width:1024px) {
                                padding: 15px;
                            }
                            @media(max-width:767px) {
                                flex-basis: 50%;
                            }
                            .table--title {
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-start;
                                padding: 0px 0px 30px;
                                @media(max-width:767px) {
                                    padding: 0px 0px 15px;
                                }
                                span {
                                    color: $dark-blue;
                                    font-weight: 500;
                                    a {
                                        color: $dark-blue;
                                    }
                                }
                            }
                            .notification--list {
                                ul {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 30px;
                                    @media(max-width:767px) {
                                        gap: 15px;
                                    }
                                    li {
                                        .notification--list--item {
                                            display: flex;
                                            align-items: flex-start;
                                            gap: 20px;
                                            @media(max-width:767px) {
                                                gap: 15px;
                                            }
                                            .thumbnuil {
                                                max-width: 100px;
                                                width: 100%;
                                                background-color: lighten($black, 95%);
                                                border: 1px solid lighten($black, 90%);
                                                border-radius: 5px;
                                                display: inline-flex;
                                                @media(max-width:767px) {
                                                    max-width: 80px;
                                                }
                                                img {
                                                    width: 100%;
                                                    aspect-ratio: 1/ 0.9;
                                                    object-fit: cover;
                                                }
                                            }
                                            .notification--data {
                                                h3 {
                                                    font-weight: 600;
                                                    color: $dark-blue;
                                                    margin-bottom: 3px;
                                                    @media(max-width:767px) {
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                    }
                                                }
                                                p {
                                                    font-size: 14px;
                                                    line-height: 24px;
                                                    display: -webkit-box;
                                                    -webkit-line-clamp: 2;
                                                    -webkit-box-orient: vertical;  
                                                    overflow: hidden;
                                                    @media(max-width:767px) {
                                                        font-size: 12px;
                                                        line-height: 16px;

                                                    }
                                                }
                                                .date {
                                                    font-size: 12px;
                                                    color: $text-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .right--cover--img {
                            flex-basis: 50%;
                            @media(max-width:767px) {
                                flex-basis: 50%;
                            }
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
                .notification--content {
                    
                    .dashboard--content--title {
                        h2 {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            font-size: 20px;
                            font-weight: 500;
                            @media(max-width:767px) {
                                font-size: 18px;
                            }
                        }
                    }
                    .subcription--update {
                        background: linear-gradient(45deg, #98ced038, #98CED0);
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        .subcription--message {
                            width: max-content;
                            h3 {
                                font-size: 14px;
                                font-weight: 500;
                                color: $dark-blue;
                                text-transform: uppercase;
                            }
                            p {
                                font-size: 12px;
                                line-height: 20px;
                                color: $black;
                            }
                        }
                        .pay--now {
                            width: max-content;
                            a {
                                height: 100%;
                                background-color: $white;
                                padding: 5px 15px;
                                font-size: 14px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                    }
                    .dashboard--notification {
                        display: flex;
                        gap: 30px;
                        margin-top: 30px;
                        @media(max-width:767px) {
                            margin-top: 20px;
                        }
                        .notification--wrapper   {
                            flex-basis: 100%;
                            
                            .table--title {
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-start;
                                padding: 0px 0px 15px;
                                span {
                                    color: $text-color;
                                    font-weight: 500;
                                }
                            }
                            .notification--list {
                                ul {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 12px;
                                    margin-bottom: 40px;
                                    @media(max-width:767px) {
                                        margin-bottom: 25px;
                                    }
                                    li {
                                        border: 1px solid lighten($black, 90%);
                                        border-radius: 10px;
                                        padding: 12px;
                                        .notification--list--item {
                                            display: flex;
                                            align-items: flex-start;
                                            gap: 20px;
                                            @media(max-width:767px) {
                                                gap: 15px;
                                            }
                                            .thumbnuil {
                                                max-width: 125px;
                                                width: 100%;
                                                background-color: lighten($black, 95%);
                                                border: 1px solid lighten($black, 90%);
                                                border-radius: 5px;
                                                display: inline-flex;
                                                @media(max-width:767px) {
                                                    max-width: 80px;
                                                }
                                                img {
                                                    width: 100%;
                                                    aspect-ratio: 1/ 0.9;
                                                    object-fit: cover;
                                                }
                                            }
                                            .notification--data {
                                                h3 {
                                                    font-weight: 600;
                                                    color: $dark-blue;
                                                    margin-bottom: 3px;
                                                    @media(max-width:767px) {
                                                        font-weight: 500;
                                                        font-size: 16px;
                                                    }
                                                }
                                                p {
                                                    font-size: 14px;
                                                    line-height: 24px;
                                                    display: -webkit-box;
                                                    -webkit-line-clamp: 2;
                                                    -webkit-box-orient: vertical;  
                                                    overflow: hidden;
                                                    color: lighten($black, 15%);
                                                    margin-bottom: 5px;
                                                    @media(max-width:767px) {
                                                        font-size: 12px;
                                                        line-height: 16px;
                                                    }
                                                }
                                                .date {
                                                    font-size: 12px;
                                                    color: $text-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .quotations--content, .quotations--details--content  {
                    .dashboard--content--title {
                        h2 {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            font-size: 20px;
                            font-weight: 500;
                            @media(max-width:767px) {
                                font-size: 18px;
                            }
                        }
                    }
                    .paginations {
                        align-items: center;
                        display: flex;
                        gap: 25px;
                        justify-content: center;
                        padding: 20px;

                        button {
                            background-color: initial;
                            border: 1px solid #e6e6e6;
                            font-weight: 500;
                            font-family: 'Poppins';
                            &::before{
                                background-color: $blue;
                            }
                            &:hover{
                                color: $black;
                            }
                        }
                    }
                    
                    .table--wrapper {
                        border: 1px solid lighten($black, 90%);
                        border-radius: 10px;
                        padding: 0px;
                        overflow-x: auto;
                        .table--title {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            padding: 15px 30px;
                            span {
                                color: $dark-blue;
                                font-weight: 500;
                                a {
                                    color: $dark-blue;
                                }
                            }
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            thead {
                                tr {
                                    
                                    th {
                                        text-align: left;
                                        font-size: 14px;
                                        font-weight: 500;
                                        padding: 25px;
                                        @media(max-width:767px) {
                                            padding: 15px;
                                        }
                                    }
                                }
                            }
                            tbody {
                                tr {
                                    &:nth-child(odd) {
                                        background-color: lighten($black, 95%);
                                    }
                                    td {
                                        text-align: left;
                                        font-size: 14px;
                                        font-weight: 400;
                                        padding: 25px;
                                        @media(max-width:767px) {
                                            padding: 15px;
                                        }
                                        &.active {
                                            color: $green;
                                        }
                                        &.cancel {
                                            color: $red;
                                        }
                                        &.processing {
                                            color: $sky;
                                        }
                                        .track--order {
                                            border: 1px solid lighten($black, 90%);
                                            padding: 5px;
                                            min-width: 70px;
                                            border-radius: 30px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;    
                                            background-color: transparent;
                                            min-height: 30px;
                                            transition: $transition;
                                            img {
                                                filter: brightness(0.1);
                                            }
                                            &:hover {
                                                background-color: $blue;
                                            }
                                        }
                                        .action--table {
                                            border: 1px solid lighten($black, 90%);
                                            padding: 6px;
                                            min-width: 70px;
                                            border-radius: 30px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;    
                                            background-color: transparent;
                                            transition: background-color 0.4s;
                                            min-height: 30px;
                                            background-image: url("../../image/eye.png");
                                            background-repeat: no-repeat;
                                            background-position: center;
                                            
                                            &:hover {
                                                background-color: $blue;
                                                background-image: url("../../image/eye2.png");
                                                
                                            }
                                        } 
                                    }
                                }
                            }
                        }
                    }
                    .paginations {
                        ul {
                            display: flex;
                            gap: 25px;
                            justify-content: center;
                            align-items: center;
                            padding: 20px;
                            @media(max-width:767px) {
                                gap: 15px;
                            }
                            .btn {
                                background-color: transparent;
                                border: 1px solid lighten($black, 90%);
                                &::before {
                                    background-color: $blue;
                                }
                                &:hover {
                                    color: black;
                                }
                                @media(max-width:767px) {
                                    padding: 10px;
                                    min-height: 40px;
                                }
                            }
                        }
                    }
                }
                .quotations--details--content {
                    .back--btn--wrapper {
                        display: flex;
                        .back--btn {
                            display: inline-block;
                            margin-right: 15px;
                        }
                    }
                    
                    
                    .table--wrapper {
                        overflow-x: hidden;
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            tbody {
                                tr {
                                    text-align: left;
                                    &:nth-child(even) {
                                        background-color: lighten($black, 95%);
                                    }
                                    &:nth-child(odd) {
                                        background-color: $white;
                                    }
                                    th {
                                        font-size: 14px;
                                        font-weight: 500;
                                        padding: 15px 25px;
                                        @media(max-width:767px) {
                                            padding: 15px;
                                        }
                                    }
                                    td {
                                        text-align: left;
                                        font-size: 14px;
                                        font-weight: 400;
                                        padding: 15px 25px;
                                        @media(max-width:767px) {
                                            padding: 15px;
                                        }
                                        &.status {
                                            color: $dark-blue;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                
                .setting--content {
                    
                    .dashboard--content--title {
                        h2 {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            font-size: 20px;
                            font-weight: 500;
                            @media(max-width:767px) {
                                font-size: 18px;
                            }
                            .edit--setting {
                                cursor: pointer;
                                background: $dark-blue;
                                border-radius: 50px;
                                width: 35px;
                                aspect-ratio: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                i {
                                    color: $white;
                                    font-size: 16px;
                                    margin-left: 4px;
                                    transition: $transition;
                                }
                            }
                            .editable {
                                background: #98ced0 !important;
                            }
                        }
                    }
                    .setting--content--wrapper {
                        border: 1px solid lighten($black, 90%);
                        border-radius: 10px;
                        padding: 30px;
                        @media(max-width:767px) {
                            padding: 15px;
                        }
                        .table--title {
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                            padding: 0px 0px 15px;
                            span {
                                color: $black;
                                font-weight: 500;
                            }
                        }
                        .user--profile {
                            display: flex;
                            gap: 30px;
                            align-items: center;
                            .user--image {
                                flex-basis: 80px;
                                display: flex;
                                border: 1px solid lighten($black, 90%);
                                border-radius: 5px;
                                overflow: hidden;
                                
                                img {
                                    width: 100%;
                                    object-fit: cover;
                                    aspect-ratio: 1;
                                }
                            }
                            .change--profile--link {
                                position: relative;
                                a {
                                    min-height: 40px;
                                    color: $dark-blue;
                                    font-weight: 400;
                                    overflow: hidden;
                                    input {
                                        position: absolute;
                                        left: -110px;
                                        top: 0;
                                        padding: 0px;
                                        min-height: 40px;
                                        opacity: 0;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }
                        .user--profile--form {
                            .form--wrapper {
                                margin-top: 20px;
                                display: grid;
                                grid-template-columns: 1fr 1fr;
                                gap: 20px;
                                @media(max-width:767px) {
                                    grid-template-columns: 1fr;
                                    gap: 15px;
                                }
                                .span--2 {
                                    grid-column: span 2;
                                    @media(max-width:767px) {
                                        grid-column: span 1;
                                    }
                                }
                                .table--title {
                                    margin-top: 15px;
                                    padding: 0px;
                                }
                                .form--group {
                                    label {
                                        font-size: 14px;
                                        font-weight: 400;
                                        margin-bottom: 7px;
                                        display: block;
                                        }
                                    input {
                                        width: 100%;
                                        font-size: 14px;
                                        border: 1px solid lighten($black, 90%);
                                        border-radius: 5px;
                                        padding: 14px 20px;
                                        background: #98ced054;
                                    }
                                    
                                    input[disabled] {
                                        opacity: 0.5;
                                        cursor: not-allowed;
                                        background: $white;
                                        color: $black;
                                    }
                                    
                                    input[disabled]::placeholder {
                                        color: $black !important;
                                    }

                                    input::placeholder {
                                        color: $black;
                                    }

                                }
                                .password--container {
                                    position: relative;

                                    .password-hide--show {
                                        position: absolute;
                                        bottom: 12px;
                                        right: 20px;
                                    }
                                }
                            }
                        }
                    }
                    
                }
                    .track--order--content {
                        .dashboard--content--title {
                            h2 {
                                display: flex;
                                justify-content: space-between;
                                align-items: flex-start;
                                font-size: 20px;
                                font-weight: 500;
                                .back--btn--wrapper {
                                    display: flex;
                                    .back--btn {
                                        display: inline-block;
                                        margin-right: 15px;
                                    }
                                }
                                @media(max-width:767px) {
                                    font-size: 18px;
                                }
                            }
                        }
                        .track--order--wrapper {
                            border: 1px solid lighten($black, 90%);
                            border-radius: 10px;
                            padding: 30px;
                            @media(max-width:767px) {
                                padding:15px;
                            }
                            .order--id {
                                h3 {
                                    font-weight: 500;
                                    font-size: 16px;
                                    margin-bottom: 10px;
                                }
                            }
                            .user--address {
                                margin-top: -40px;
                                h3 {
                                    font-weight: 500;
                                    font-size: 16px;
                                    margin-bottom: 15px;
                                }
                                ul {
                                    display: flex;
                                    gap: 5px;
                                    font-size: 14px;
                                    font-weight: 500;
                                    margin-bottom: 5px;
                                }
                                p {
                                    color: $black;
                                    font-size: 14px;
                                }
                            }
                            .order--status {
                                font-weight: 500;
                                color: $dark-blue;
                            }
                            .order--tracking--bar {
                                padding: 30px 0px;
                                ul {
                                    display: flex;
                                    flex-direction: column;
                                    li {
                                        display: flex;
                                        gap: 25px;
                                        min-height: 90px;
                                        position: relative;
                                        align-items: flex-start;
                                        &::before {
                                            content: "";
                                            width: 2px;
                                            height: 100%;
                                            background-color: lighten($black, 85%);
                                            position: absolute;
                                            left: 6px;
                                            bottom: 80px;
                                            z-index: -1;
                                        }
                                        &:first-child::before {
                                            display: none;
                                        }
                                        &.active {
                                            &::before {
                                                background-color: $dark-blue;
                                            }
                                            .track--circle {
                                                &::before {
                                                    background-color: $dark-blue;
                                                }
                                            }
                                        }
                                        &:nth-child(1) {
                                            &::before {
                                                display: none;
                                            }
                                        }
                                        
                                        .track--circle {
                                            display: inline-block;
                                            min-width: 14px;
                                            min-height: 14px;
                                            border-radius: 50%;
                                            background-color: lighten($black, 85%);
                                            position: relative;
                                            &::before {
                                                content: "";
                                                display: inline-block;
                                                min-width: 6px;
                                                min-height: 6px;
                                                border-radius: 50%;
                                                background-color: $white;
                                                position: absolute;
                                                left: 50%;
                                                top: 50%;
                                                transform: translate(-50%, -50%);
                                            }
                                        }
                                        .track--detail {
                                            h4 {
                                                font-size: 14px;
                                                font-weight: 400;
                                            }
                                            p {
                                                font-size: 14px;
                                                font-weight: 400;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
            }
        }
    }
}

.table--wrapper {
&::-webkit-scrollbar {
    height: 3px;
}
&::-webkit-scrollbar-track {
    background: lighten($black, 95%); 
  }
&::-webkit-scrollbar-thumb {
    background: lighten($black, 85%); 
  }
  &::-webkit-scrollbar-thumb:hover {
    background: lighten($black, 80%); 
  }
}